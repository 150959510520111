<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.CHANGE_QUANTITY_ORDER}} {{orderSelected.orderNumber}} </h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" sm="4">
            <h2 class="caption">{{$_strings.order.UNIT}}</h2>
            <div class="d-flex">
              <v-select
                v-model="form.unit"
                :items="itemsTypeCost"
                hide-details
                dense
                outlined
                :disabled="isCalculated"
              >
              </v-select>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              hide-default-footer
              :headers="headers"
              calculate-widths
              :items="form.details"
              :items-per-page="1000"
            >
              <template v-slot:[`item.travelDocumentNo`]={item}>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="item.travelDocumentNo"
                  :disabled="isCalculated"
                ></v-text-field>
              </template>
              <template v-slot:[`item.travelDocumentDate`]="{item, index}">
                <v-menu
                  :ref="menuTravelDocumentDate[index]"
                  v-model="menuTravelDocumentDate[index]"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details
                      outlined
                      class="caption"
                      placeholder="Tanggal"
                      :value="formatDateID(item.travelDocumentDate)"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :disabled="isCalculated"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.travelDocumentDate"
                    no-title
                    :max="dayjs().format()"
                    @change="menuTravelDocumentDate[index] = false"
                    :disabled="isCalculated"
                    locale="id"
                  >
                  </v-date-picker>
                </v-menu>
              </template>
              <template v-slot:[`item.tonnage`]={item}>
                <v-text-field
                  v-model="item.tonnage"
                  v-mask="{'alias': 'decimal',rightAlign: false}"
                  :disabled="isCalculated"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:[`item.cubic`]={item}>
                <v-text-field
                  v-model="item.cubic"
                  v-mask="{'alias': 'decimal',rightAlign: false}"
                  :disabled="isCalculated"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:[`item.carton`]={item}>
                <v-text-field
                  v-model="item.carton"
                  v-mask="{'alias': 'integer',rightAlign: false}"
                  :disabled="isCalculated"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:[`item.deleteRow`]=item>
                <v-icon
                  @click="deleteRow(item)"
                  color="red"
                  :disabled="isCalculated"
                >
                  mdi-minus-circle
                </v-icon>
              </template>
            </v-data-table>
            <div v-if="!isCalculated" class="mt-2">
              <v-btn
                @click="addRowTable"
                class="pa-0"
                text
              >
                <v-icon color="primary">mdi-plus-circle</v-icon>
                <span class="primary--text">Tambah Baris</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!isCalculated">
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="saveQty"
          :disabled="isLoading || !form.details.length"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat, convertTextTypeCost } from '@/helper/commonHelpers';

export default {
  props: {
    isDelivery: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.form = {
          shipmentsId: '',
          unit: '',
          details: [],
        };
      }
      if (newVal) this.fetchData();
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isLoadingListQty: false,
      menuTravelDocumentDate: [],
      headers: [
        {
          text: this.$_strings.order.NO_ROAD_LETTER,
          value: 'travelDocumentNo',
          class: 'white--text primary',
          sortable: false,
          width: '180px',
        },
        {
          text: this.$_strings.common.DATE,
          value: 'travelDocumentDate',
          class: 'white--text primary',
          sortable: false,
          width: '150px',
        },
        {
          text: this.$_strings.order.MASSA,
          value: 'tonnage',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.CUBIC,
          value: 'cubic',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.BOX_CARTON,
          value: 'carton',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: '',
          value: 'deleteRow',
          class: 'white--text primary',
          sortable: false,
        },
      ],
      items: [],
      itemsTypeCost: [],
      orderSelected: {},
      form: {
        shipmentsId: '',
        unit: '',
        details: [],
      },
    };
  },
  computed: {
    isCalculated() {
      return this.orderSelected.isCalculated;
    },
  },
  methods: {
    dayjs,
    convertTextTypeCost,
    async fetchData() {
      await this.getUnitPrice();
      await this.getShipmentQty();
    },
    async getShipmentQty() {
      this.isLoading = true;
      try {
        const { id } = this.orderSelected;
        const { unit } = this.form;
        if (!unit) return;
        const result = await this.$_services.order.getShipmentQty(id, unit, this.isDelivery);
        if (result.data) {
          this.form = {
            ...result.data,
            details: result.data.details.map((quantity) => (
              {
                ...quantity,
                travelDocumentDate: dayjs(quantity.travelDocumentDate).format('YYYY-MM-DD'),
              }
            )),
          };
        }
      } catch {
        this.form = { ...this.form, details: [] };
      } finally {
        this.isLoading = false;
      }
    },
    async getUnitPrice() {
      this.isLoading = true;
      try {
        const { id } = this.orderSelected;
        const result = await this.$_services.order.getUnitPrice(id);
        this.itemsTypeCost = [{
          text: this.convertTextTypeCost(result.data.unit),
          value: result.data.unit,
        }];
        this.form.unit = result.data.unit;
      } finally {
        this.isLoading = false;
      }
    },
    addRowTable() {
      this.form.details.push({
        id: null,
        travelDocumentNo: '',
        travelDocumentDate: '',
        tonnage: 0,
        cubic: 0,
        carton: 0,
      });
    },
    deleteRow(item) {
      this.form.details.splice(item.index, 1);
    },
    formatDateID(date) {
      if (!date) return null;
      return dateFormat(date);
    },
    formValidate() {
      const { unit } = this.form;
      const { serviceType } = this.orderSelected;
      let valid = true;
      let isValidFormLcl = true;
      if (serviceType === 'LCL') {
        Object.keys(this.form.details).forEach((index) => {
          Object.keys(this.form.details[index]).forEach((nameObj) => {
            if (!this.form.details[index][nameObj]
              && (this.form.details[index][nameObj] !== 0 && unit.toLowerCase() !== nameObj)
              && nameObj !== 'id'
              && nameObj !== 'quantity'
            ) {
              valid = false;
            }
            if (
              (!this.form.details[index][nameObj]
                || this.form.details[index][nameObj] < 0
              ) && unit.toLowerCase() === nameObj
            ) {
              isValidFormLcl = false;
            }
          });
        });
      } else {
        Object.keys(this.form.details).forEach((index) => {
          Object.keys(this.form.details[index]).forEach((nameObj) => {
            if (!this.form.details[index][nameObj]
              && this.form.details[index][nameObj] !== 0
              && nameObj !== 'id'
              && nameObj !== 'quantity'
            ) {
              valid = false;
            }
          });
        });
      }
      if (!isValidFormLcl) {
        this.$dialog.notify.error(`${this.convertTextTypeCost(unit)} harus lebih dari 0`);
        return false;
      }
      if (isValidFormLcl && !valid) {
        this.$dialog.notify.error('Mohon periksa field yang belum diisi');
        return false;
      }
      return true;
    },
    async saveQty() {
      this.isLoading = true;
      try {
        // CHECK VALUE MUST NOT NULL
        if (!this.formValidate()) {
          return;
        }
        const data = {
          details: this.form.details.map((qty) => ({
            ...qty,
            travelDocumentDate: dayjs(qty.travelDocumentDate).format('YYYY-MM-DD'),
          })),
        };
        const { id } = this.orderSelected;
        const { unit } = this.form;
        await this.$_services.order.saveShipmentQty(id, unit, data, this.isDelivery);
        this.form = {
          shipmentsId: '',
          unit: '',
          details: [],
        };
        this.$dialog.notify.success('Berhasil menyimpan');
        this.$emit('handleAfterChangeQty');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
